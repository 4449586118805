@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&family=Poppins:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto slab', sans-serif; /* Utilisez la police que vous avez choisie */
}

h1, h2, h3, h4 {
  font-family: 'Montserrat', sans-serif; /* Utilisez une police différente pour les titres si souhaité */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
